<template>
  <docs-layout>
    <div class="space-y-4 pb-10 w-full text-left">
      <h2 class="text-2xl font-bold">Input date</h2>
      <p>We use DatePicker of v-calendar@next for this component</p>
      <p>A few props of DatePicker component have been exposed for usage, including range mode</p>
      <p>To add more, please refer to <a href="https://vcalendar.io/datepicker.html" target="_blank">v-calendar documentation</a> for further details.</p>
      <h3 class="text-xl font-bold">Default</h3>
      <div class="w-1/2">
        <asom-input-date v-model="date" />
      </div>
      <p>Selected date: {{parseDateTime(date)}}</p>
      <h3 class="text-xl font-bold">Input State</h3>
      <div class="space-y-4 pb-10 w-1/2 text-left">
        <asom-input-date v-for="(s, i) in states"
          :key="i"
          :state="s"
          placeholder="Placeholder"
        />
      </div>
      <h2 class="text-2xl font-bold">Input date range</h2>
      <h3 class="text-xl font-bold">Default</h3>
      <div class="w-1/2 space-y-4">
        <asom-input-date-range v-model="dateRange" />
        <p>Selected date range: {{dateRange}}</p>
      </div>
      <h3 class="text-xl font-bold">Input State</h3>
      <div class="space-y-4 pb-10 w-1/2 text-left">
        <asom-input-date-range v-for="(s, i) in states"
          :key="i"
          :state="s"
        />
      </div>
      <h2 class="text-2xl font-bolc">Input time</h2>
      <div class="w-1/2 space-y-4">
        <asom-input-date-time v-model="time"  pickerMode="time" />
        <p>Selected time: {{parseDateTime(time)}}</p>
      </div>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'
import { parseDateTime } from '@/helpers/dateTimeHelpers'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      date: null,
      dateRange: null,
      time: new Date(),
      states: [ 'default', 'error' ]
    }
  },
  methods: {
    parseDateTime,
  }
}
</script>